export function yearFromDate(date: string): number {
  return new Date(Date.parse(date)).getFullYear();
}

/**
 * Because I'm neurotic I prefer British dates. They just make more sense.
 *
 * @param date The date to format
 * @returns A human readable date string
 */
export function humanReadableDate(date: string): string {
  return new Date(Date.parse(date)).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function tabularDate(date: string): string {
  return new Date(Date.parse(date)).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
}

export function isoDatetime(date: string): string {
  return new Date(Date.parse(date)).toISOString();
}

export function isoDate(date: string): string {
  return isoDatetime(date).substring(0, 10);
}
