export const UNTITLED = "Untitled";

export enum Author {
  Kara = "kara",
  Ryan = "ryan",
}

export interface MDXContent<T> {
  id: string;
  frontmatter: Partial<T>;
  excerpt: string | undefined;
  body: string;
  rawBody?: string;
}

export interface Media {
  url: string;
  title: string;
  /**
   * MIME type for the media
   */
  type: string;
  /**
   * Duration in seconds
   */
  duration: number;
  /**
   * Size in bytes
   */
  size: number;
  /**
   * URL to a poster image for the media
   */
  image: string;
}

export type Post = MDXContent<{
  author: Author;
  slug?: string;
  permalink: string;
  title: string;
  date: string;
  excerpt: string;
  image: string;
  media: Media;
}>;

export type Page = MDXContent<{
  path: string;
  title: string;
  description: string;
  image: string;
}>;

export type Snippet = MDXContent<{
  page: string;
  section: string;
  title: string;
}>;

export interface MDXFile<T> {
  sourceInstanceName: string;
  id: string;
  name: string;
  childMdx: T;
}

export interface MDXFileQuery<T> {
  allFile: {
    nodes: Array<MDXFile<T>>;
  };
}

export type PagesQueryResult = MDXFileQuery<Page>;

export interface PostQueryResult {
  mdx: Post;
  previous: Post;
  next: Post;
}

export interface PageQueryResult {
  mdx: Page;
}

// These values are defined in gatsby/config.ts
export interface SiteMetadata {
  author: string;
  description: string;
  language: string;
  siteUrl: string;
  title: string;
}

export interface SiteMetadataQueryResult {
  site: {
    siteMetadata: SiteMetadata;
  };
}

export interface SnippetNode {
  sourceInstanceName: "snippets";
  id: string;
  name: string;
  childMdx: Snippet;
}

export interface SnippetQueryResult {
  file: SnippetNode;
}

export interface AllSnippetsQueryResult {
  allFile: {
    nodes: SnippetNode[];
  };
}

export interface BlogQueryResultItem {
  id: string;
  childMdx: Post;
}
export interface BlogQueryResult {
  allFile: {
    nodes: BlogQueryResultItem[];
  };
}

export interface BibliographyResultItem {
  id: string;
  sourceInstanceName: "bibliography" | "snippets";
  childMdx: MDXContent<{
    title: string;
    date: string;
    credit?: string;
    url?: string;
    image?: string;
    section?: string;
  }>;
}

export interface BibliographyResult {
  allFile: {
    nodes: BibliographyResultItem[];
  };
}

export const RaceType = {
  Swim: "swim",
  Bike: "bike",
  Run: "run",
  Segments: "segments",
} as const;

export type RaceTypes = typeof RaceType[keyof typeof RaceType];

export function isRaceType(type: string): type is RaceTypes {
  return Object.values(RaceType).includes(type as any);
}

export interface RaceDistance {
  type: RaceTypes;
  distance: string;
}

export interface RaceResultItem {
  id: string;
  name: string;
  childMdx: MDXContent<{
    title: string;
    date: string;
    distances: RaceDistance[];
    place: string;
    time: string;
    url: string;
    image: string;
  }>;
}

export interface ResultsQuery {
  intro: MDXFile<Snippet>;
  results: {
    nodes: RaceResultItem[];
  };
}

export type FeedQueryResult = {
  posts: BlogQueryResult["allFile"];
  results: ResultsQuery["results"];
} & SiteMetadataQueryResult;
