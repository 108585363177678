/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { MDXProvider } from "@mdx-js/react";

// Imported globally because it defines CSS reset on common elements.
import "../global.css";

import * as classNames from "./Layout.module.css";
import SiteNavigation, { SiteNavigationItem } from "./SiteNavigation";
import Footer from "./Footer";
import ContentImage from "./ContentImage";
import ContentVideo from "./ContentVideo";
import Figure from "./Figure";
import MarkdownElements from "./MarkdownElements";
import MediaEntry from "./MediaEntry";
import WordMark from "./WordMark";
import Monogram from "./Monogram";

export type LayoutProps = {
  monoNav?: boolean;
};

const components = {
  ContentImage,
  ContentVideo,
  Figure,
  MediaEntry,
  ...MarkdownElements,
  Monogram,
};

const Layout: React.FC<LayoutProps> = ({ monoNav = true, children = [] }) => {
  const navItems: SiteNavigationItem[] = [
    {
      href: "/archive/",
      children: "Blog",
    },
    {
      href: "/bibliography/",
      children: "Bibliography",
    },
    {
      href: "/results/",
      children: "Race Results",
    },
    {
      href: "https://github.com/kara-ryli",
      target: "_blank",
      children: "Code",
    },
  ];
  return (
    <div className={classNames.root}>
      <MDXProvider components={components}>
        <SiteNavigation items={navItems} />
        {monoNav && <WordMark className={classNames.nav} />}
        <main className={classNames.main}>{children}</main>
        <Footer className={classNames.footer} />
      </MDXProvider>
    </div>
  );
};

export default Layout;
