import React, {
  FC,
  useCallback,
  useState,
  MouseEventHandler,
  HTMLAttributes,
  AnchorHTMLAttributes,
} from "react";
import cx from "classnames";
import * as classNames from "./SiteNavigation.module.css";
import Hamburger from "./Hamburger";
import CloseButton from "./CloseButton";
import { CSSTransition } from "react-transition-group";
import SafeLink from "./SafeLink";

export type SiteNavigationItem = AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string;
};
export type SiteNavigationProps = HTMLAttributes<HTMLElement> & {
  items?: SiteNavigationItem[];
};

const SiteNavigation: FC<SiteNavigationProps> = ({
  items = [],
  className,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggleClick = useCallback<MouseEventHandler>(
    (e) => {
      e.preventDefault();
      setIsOpen(!isOpen);
    },
    [isOpen]
  );
  const onLinkClick = useCallback<MouseEventHandler>((_e) => {
    setIsOpen(false);
  }, []);
  return (
    <CSSTransition in={isOpen} timeout={300} classNames={{ ...classNames }}>
      <nav
        className={cx(classNames.root, isOpen && classNames.open, className)}
        {...props}
      >
        <Hamburger
          className={classNames.toggle}
          onClick={onToggleClick}
          aria-label="Navigation"
        />
        <div className={classNames.panel}>
          <ul className={classNames.list}>
            {items.map(({ href, children, ...props }, i) => (
              <li key={i} className={classNames.item}>
                <SafeLink
                  url={href}
                  // Need to use the click handler here to ensure navigation closes
                  // when the page is the current page.
                  linkProps={{ onClick: onLinkClick, ...props }}
                  anchorProps={{
                    onClick: onLinkClick,
                    rel: "noopener noreferrer",
                    ...props,
                  }}
                >
                  {children}
                </SafeLink>
              </li>
            ))}
          </ul>
          <CloseButton
            className={classNames.close}
            onClick={onToggleClick}
            aria-label="Close"
          />
        </div>
      </nav>
    </CSSTransition>
  );
};

export default SiteNavigation;
