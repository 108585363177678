import React, { FC, HTMLAttributes, ReactNode } from "react";
import cx from "classnames";
import * as classNames from "./Figure.module.css";

export type FigureProps = HTMLAttributes<HTMLElement> & {
  blend?: boolean;
  caption?: ReactNode;
  captionId?: string;
};

/**
 * A figure with optional caption.
 */
const Figure: FC<FigureProps> = ({
  blend,
  caption,
  captionId,
  className,
  children,
  ...props
}) => (
  <figure
    className={cx(classNames.root, className, { [classNames.blend]: blend })}
    {...props}
  >
    {children}
    {caption && (
      <figcaption id={captionId} className={classNames.caption}>
        {caption}
      </figcaption>
    )}
  </figure>
);

export default Figure;
