import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import * as classNames from "./CloseButton.module.css";

export type CloseButtonProps = HTMLAttributes<HTMLButtonElement> & {};

/**
 * A button containing an SVG "X"
 */
const CloseButton: FC<CloseButtonProps> = ({ className, ...props }) => (
  <button className={cx(classNames.root, className)} {...props}>
    <svg className={classNames.svg} viewBox="0 0 87 87">
      <path
        className={classNames.path}
        d="M26.000,26.000 L62.000,62.000 L26.000,26.000 Z"
      />
      <path
        className={classNames.path}
        d="M62.000,26.000 L26.000,62.000 L62.000,26.000 Z"
      />
    </svg>
  </button>
);

export default CloseButton;
