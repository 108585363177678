import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import * as classNames from "./Hamburger.module.css";

export type HamburgerProps = HTMLAttributes<HTMLButtonElement> & {};

/**
 * A button containing three stacked horizontal lines
 */
const Hamburger: FC<HamburgerProps> = ({ className, ...props }) => (
  <button className={cx(classNames.root, className)} {...props}>
    <svg className={classNames.svg} viewBox="0 0 64 64">
      <path
        className={classNames.path}
        d="M14.000,16.500 L50.000,16.500 L14.000,16.500 Z"
      />
      <path
        className={classNames.path}
        d="M14.000,32.500 L50.000,32.500 L14.000,32.500 Z"
      />
      <path
        className={classNames.path}
        d="M14.000,47.500 L50.000,47.500 L14.000,47.500 Z"
      />
    </svg>
  </button>
);

export default Hamburger;
