export const FeedPath = {
  RSS: "/rss.xml",
  ATOM: "/atom.xml",
  JSON: "/feed.json",
} as const;
export type FeedUrl = typeof FeedPath[keyof typeof FeedPath];

export const StaticPath = {
  FAVICON: "/favicon.ico",
  HIGH_QUALITY_ICON: "/images/favicons/icon-512x512.png",
  ICON_MASK_IMAGE: "/images/favicons/mask-icon.svg",
  HUMANS_TXT: "/humans.txt",
} as const;

export const MASK_IMAGE_COLOR = "#551A8B";

export enum Media {
  Exif = ".exif.json",
  Webp = ".webp",
  WebpSmall = ".small.webp",
  Jpeg = ".jpg",
  Webm = ".webm",
  MP4 = ".mp4",
}

export function getUrl(baseUrl: string, media: Media): string {
  return baseUrl + media;
}

// Determine based on URL whether the image is a content image or a regular image.
// We're basically just testing if it's got a "." in the last section of the URL and
// assuming that a file extension if so.
export function isImageUrl(url: string): boolean {
  return /[^/]\.[a-z0-9]+$/i.test(url);
}

export function getResponsiveImageUrls(src: string) {
  return {
    webp: getUrl(src, Media.Webp),
    smol: getUrl(src, Media.WebpSmall),
    jpeg: getUrl(src, Media.Jpeg),
  };
}
