import React, { HTMLAttributes, ReactNode, ReactElement } from "react";
import cx from "classnames";
import * as classNames from "./MarkdownElements.module.css";

let counter = 0;

function getNodeText(node: ReactNode): string {
  switch (typeof node) {
    case "string":
      return node;
    case "number":
    case "boolean":
      return `${node}`;
    case "object":
      return Array.isArray(node)
        ? node.map(getNodeText).join("")
        : getNodeText((node as ReactElement)?.props?.children);
    default:
      return "";
  }
}

function genId(node: ReactNode): string {
  const text =
    getNodeText(node).replace(/[^A-Za-z0-9\-_:\.]/g, "_") || `${counter++}`;
  return `node-${text}`;
}

function heading(level: string): React.FC<HTMLAttributes<HTMLElement>> {
  const result: React.FC<HTMLAttributes<HTMLElement>> = ({
    className,
    children,
    ...props
  }) => {
    const el = `h${level}`;
    return React.createElement(
      el,
      {
        id: genId(children),
        className: cx(classNames[el], className),
        ...props,
      },
      children
    );
  };
  return result;
}

export const H2 = heading("2");
export const H3 = heading("3");
export const H4 = heading("4");
export const H5 = heading("5");
export const H6 = heading("6");

export const Table: React.FC<HTMLAttributes<HTMLTableElement>> = ({
  children,
  className,
  ...props
}) => (
  <div className={classNames.tableWrap}>
    <table className={cx(classNames.table, className)} {...props}>
      {children}
    </table>
  </div>
);

export default {
  h1: H2,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  table: Table,
} as const;
