import React, { FC, HTMLAttributes, ReactNode } from "react";
import * as classNames from "./MediaEntry.module.css";
import MarkdownContent from "./MarkdownContent";
import cx from "classnames";
import { H3 } from "./MarkdownElements";
import { UNTITLED } from "../graphql";
import SafeLink from "./SafeLink";
import { AnchorHTMLAttributes } from "react";
import { yearFromDate } from "../util/dates";

export type MediaEntryProps = HTMLAttributes<HTMLDivElement> & {
  title?: ReactNode;
  date?: string;
  credit?: ReactNode;
  url?: string;
  image?: string;
  html?: string;
};

/**
 * Returns the content of the title elements based on the the combination
 * of URL and title. Gracefully handles missing URLs, internal and external,
 * including custom protocol URLs.
 *
 * @param title The title of the entry
 * @param url The url of the entry
 */
export function getLinkedNode(content: ReactNode, url?: string): ReactNode {
  // Empty URLs should just be the title
  if (!url) {
    return content;
  }
  // // External URLs use <a>
  // if (isGatsbyLink(url)) {
  //   return (
  //     <a href={url} rel="noopener noreferrer">
  //       {content}
  //     </a>
  //   );
  // }
  // // Internal URLs use <Link>
  // return <Link to={url}>{content}</Link>;

  return (
    <SafeLink url={url} anchorProps={{ rel: "noopener noreferrer" }}>
      {content}
    </SafeLink>
  );
}

export interface MediaEntryLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {}

const MediaEntryLink: FC<MediaEntryLinkProps> = ({
  href,
  className,
  children,
  ...props
}) => {
  const mediaEntryLinkClassName = cx(classNames.link, className);
  return href && typeof href === "string" ? (
    <SafeLink
      url={href}
      anchorProps={{ className: mediaEntryLinkClassName, ...props }}
    >
      {children}
    </SafeLink>
  ) : (
    <>{children}</>
  );
};

/**
 * Excerpt to a blog post
 */
const MediaEntry: FC<MediaEntryProps> = ({
  className,
  title = UNTITLED,
  date,
  credit,
  url,
  image,
  html,
  children,
  ...props
}) => (
  <div {...props} className={cx(classNames.root, className)}>
    {image && (
      <div className={classNames.imageContainer}>
        <MediaEntryLink href={url}>
          <img
            className={classNames.image}
            src={image}
            alt=""
            role="presentation"
          />
        </MediaEntryLink>
      </div>
    )}
    <div className={classNames.content}>
      <H3 className={classNames.title}>
        <MediaEntryLink href={url} className={classNames.titleLink}>
          {title}
          {date && <>&nbsp;({yearFromDate(date)})</>}
        </MediaEntryLink>
      </H3>
      {credit && <p className={classNames.credit}>{credit}</p>}
      {(html || children) && (
        <MarkdownContent html={html}>{children}</MarkdownContent>
      )}
    </div>
  </div>
);

export default MediaEntry;
