// extracted by mini-css-extract-plugin
export var root = "SiteNavigation-module--root--CuhEn";
export var open = "SiteNavigation-module--open--3mFEL";
export var close = "SiteNavigation-module--close--3_ijY";
export var toggle = "SiteNavigation-module--toggle--3Q2Ai";
export var panel = "SiteNavigation-module--panel--KSot-";
export var list = "SiteNavigation-module--list--3sSdB";
export var item = "SiteNavigation-module--item--20GRa";
export var enter = "SiteNavigation-module--enter--2ghoa";
export var enterActive = "SiteNavigation-module--enterActive--2lree";
export var enterDone = "SiteNavigation-module--enterDone--LSFXs";
export var exit = "SiteNavigation-module--exit--2YZYs";
export var exitActive = "SiteNavigation-module--exitActive--3rQbh";