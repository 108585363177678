import { useState } from "react";

const counterMap = new Map<string, number>();
const HAS_WHITE_SPACE = /\s/;

/**
 * Generates a persistent, unique ID for use in an HTML ID attribute, optionally
 * including a configurable prefix. Generated IDs are stored as state, and are
 * therefore not regenerated if the namespace changes.
 *
 * Usage:
 *
 *     import React, { FC } from "react";
 *     import useHtmlId from "use-html-id";
 *
 *     const MyComponent : FC<{}> = () => {
 *       const id = useHtmlId();
 *       return (<>
 *         <label htmlFor={id}>This is a label</label>
 *         <input id={id} placeholder="This is a field." />
 *       </>);
 *     };
 *
 * @param namespace A string value to use as a prefix to the numeric counter.
 */
export default function useHtmlId(namespace = "id_"): string {
  const [id] = useState<string>(() => {
    if (HAS_WHITE_SPACE.test(namespace)) {
      throw new Error(
        "useUniqueId: ID attributes cannot contain whitespace characters."
      );
    }
    const counter = (counterMap.get(namespace) || -1) + 1;
    counterMap.set(namespace, counter);
    return `${namespace}${counter}`;
  });
  return id;
}
