/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { SiteMetadataQueryResult } from "../graphql";

type MetaProps = JSX.IntrinsicElements["meta"];

export interface HeadProps {
  author?: string;
  description?: string;
  image?: string;
  language?: string;
  meta?: MetaProps[];
  title?: string;
  type?: string;
}

const Head: React.FC<HeadProps> = (props) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery<SiteMetadataQueryResult>(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            language
            siteUrl
            title
          }
        }
      }
    `
  );
  const {
    author = siteMetadata.author,
    description = siteMetadata.description,
    language = siteMetadata.language,
    meta = [],
    title = siteMetadata.title,
    type = "website",
  } = props;
  const ogMeta: MetaProps[] = [
    {
      name: `description`,
      content: description,
    },
    {
      name: `author`,
      content: author,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:locale`,
      content: language.replace("-", "_"),
    },
    {
      property: `og:site_name`,
      content: siteMetadata.title,
    },
    {
      property: `og:type`,
      content: type,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={props.title}
      defaultTitle={siteMetadata.title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={ogMeta.concat(meta)}
    >
      {props.children}
    </Helmet>
  );
};

export default Head;
