import React, { AnchorHTMLAttributes, FC } from "react";
import { Link, GatsbyLinkProps } from "gatsby";

/**
 * Checks if a URL is managed by Gatsby (and is safe to use with <Link />) or not
 *
 * False for:
 *
 *  https://www.example.com
 *  http://www.example.com
 *  //www.example.com
 *  made-up-protocol://www.example.com
 *
 * As well as paths under static
 *
 * @param url The URL to test
 */
export function isGatsbyLink(url: string): boolean {
  return !(
    /^(?:[^\/\.]+:)?\/\//.test(url) ||
    /^\/(?:\.well-known|assets|fonts|images|keys|yuiconf201\d)/.test(url)
  );
}

export interface SafeLinkProps<TState> {
  url: string;
  commonProps?: Omit<
    AnchorHTMLAttributes<HTMLAnchorElement> | GatsbyLinkProps<TState>,
    "ref"
  >;
  linkProps?: Omit<GatsbyLinkProps<TState>, "to" | "ref">;
  anchorProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
}

/**
 * Returns either a standard HTML link or a Gatsby <Link /> based on whether
 * the URL appears to be managed by Gatsby or not. Useful when leveraging
 * potentially heterogenous content.
 */
const SafeLink: FC<SafeLinkProps<any>> = ({
  url,
  commonProps,
  linkProps,
  anchorProps,
  children,
}) =>
  isGatsbyLink(url) ? (
    <Link to={url} {...linkProps} {...commonProps}>
      {children}
    </Link>
  ) : (
    <a href={url} {...anchorProps} {...commonProps}>
      {children}
    </a>
  );

export default SafeLink;
