import React, { HTMLAttributes, FC } from "react";
import cx from "classnames";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as classNames from "./MarkdownContent.module.css";

export type MarkdownContentProps = HTMLAttributes<HTMLDivElement> & {
  html?: string;
  brief?: boolean;
  dropcap?: boolean;
};

export const brief = "brief";

const MarkdownContent: FC<MarkdownContentProps> = ({
  className,
  brief = false,
  dropcap = false,
  html,
  children,
  ...props
}) => (
  <div
    className={cx(
      classNames.content,
      { [classNames.brief]: brief, [classNames.dropcap]: dropcap },
      className
    )}
    {...props}
  >
    {children}
    {html && <MDXRenderer>{html}</MDXRenderer>}
  </div>
);

export default MarkdownContent;
