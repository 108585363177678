import React, { FC, VideoHTMLAttributes } from "react";
import { Media, getUrl } from "../util/urls";

export type ContentVideoProps = VideoHTMLAttributes<HTMLVideoElement> & {
  alt?: string;
  src: string; // src is not optional
};

/**
 * A ContentVideo is a multi-source video intended to optimize for download size
 * and responsiveness. It works by leveraging naming conventions generated by
 * `bin/media`.
 *
 * @example
 *
 * // Note the lack of extension
 * <ContentImage src="/assets/path/to/basename" alt="example alt text" />
 *
 * @param param0
 * @returns
 */
const ContentVideo: FC<ContentVideoProps> = ({
  alt,
  poster,
  src,
  ...props
}) => (
  <video poster={poster} {...props}>
    <source src={getUrl(src, Media.Webm)} type="video/webm" />
    <source src={getUrl(src, Media.MP4)} type="video/mp4" />
    {poster && <img alt={alt} src={poster} />}
  </video>
);

export default ContentVideo;
