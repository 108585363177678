import React, { FC, HTMLAttributes } from "react";
import { Media, getResponsiveImageUrls, isImageUrl } from "../util/urls";

export type ContentImageProps = HTMLAttributes<HTMLPictureElement> & {
  src: string;
  alt: string;
};

/**
 * A ResponsiveImage is a multi-source image intended to optimize for download size
 * and responsiveness. They work by leveraging naming conventions generated by
 * `bin/media`.
 *
 * @example
 *
 * // Note the lack of extension
 * <ResponsiveImage src="/assets/path/to/basename" alt="example alt text" />
 *
 * @param param0
 * @returns
 */
const ResponsiveImage: FC<ContentImageProps> = ({ alt, src, ...props }) => {
  const { webp, smol, jpeg } = getResponsiveImageUrls(src);
  return (
    <picture {...props}>
      <source srcSet={`${webp} 1280w, ${smol} 640w`} type="image/webp" />
      <img alt={alt} src={jpeg} />
    </picture>
  );
};

// A ContentImage is flexibly a ResponsiveImage or a norma <img /> based on the url
const ContentImage: FC<ContentImageProps> = (props) =>
  isImageUrl(props.src) ? <img {...props} /> : <ResponsiveImage {...props} />;

export default ContentImage;
