import React, { HTMLAttributes } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import cx from "classnames";
import { SiteMetadataQueryResult } from "../graphql";
import Monogram from "./Monogram";
import * as classNames from "./WordMark.module.css";

const WordMark: React.FC<HTMLAttributes<HTMLAnchorElement>> = ({
  className,
  ...props
}) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery<SiteMetadataQueryResult>(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            language
            siteUrl
            title
          }
        }
      }
    `
  );
  return (
    <Link to="/" className={cx(classNames.root, className)} {...props}>
      <Monogram className={classNames.logo} border={true} />
      <span className={classNames.title}>{siteMetadata.title}</span>
    </Link>
  );
};

export default WordMark;
